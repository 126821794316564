header {
  padding: 15px 0;
  min-height: 590px;
  height: 100vh;
  padding-top: 100px;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

header:before {
  content: '';
  display: block;
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
}

header .container {
  position: relative;
  max-width: 1080px;
  padding-left: 40px;
  padding-right: 40px;
}

header .logo {
  height: 255px;
  width: 255px;
}
.site-text {
  font-size: 42px;
  max-width: 800px;
  width: 100%;
  margin: 150px auto 120px;
  text-align: center;
  font-family: 'Marck Script', cursive;
}
.title {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  line-height: 50px;
  margin: 0;
}
.text {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  color: #fff;
  margin: 20px 0 40px;
  width: 100%;
  max-width: 550px;
}
.buttons-block {
  display: flex;
  flex-direction: row;
}
.buttons-block .btn-orange {
  margin-left: 26px;
}
.buttons-block .btn-orange:first-child {
  margin-left: 0;
}

@media screen and (max-width: 992px) {
  header {
    min-height: 630px;
  }

  header:before {
    left: 0;
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  header .logo {
    margin: 0 auto;
  }

  header {
    min-height: 840px;
  }
}

@media screen and (max-width: 575px) {
  header {
    min-height: 630px;
  }
  header .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .site-text {
    font-size: 30px;
  }
  .title {
    font-size: 45px;
  }
  .buttons-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buttons-block .btn-orange {
    margin-left: 0;
    margin-top: 20px;
  }
  .buttons-block .btn-orange:first-child {
    margin-top: 0;
  }
}

@media screen and (max-width: 440px) {
  .site-text {
    font-size: 25px;
  }
  .text {
    font-size: 17px;
  }
}

@media screen and (max-width: 320px) {
  header {
    min-height: 570px;
  }

  .navbar-brand {
    margin-right: 0.5rem;
  }
}
