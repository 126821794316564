#team {
  background: #c7c7c7;
}
#team > .container {
  max-width: 1080px;
  padding-left: 40px;
  padding-right: 40px;
}
.team-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.member-block {
  width: 100%;
  max-width: 360px;
  padding: 0;
  position: relative;
}

.member-block a {
  display: block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  opacity: 1;
}

.member-block a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 3));
  opacity: 0.75;
  transition: all 0.2s ease-in-out;
}

.member-block a:hover:before {
  opacity: 0.5;
}

.member-block a img {
  max-width: 100% !important;
  width: 100%;
  transition: all 0.2s ease-in-out;
  margin-left: 0;
  margin-top: 0;
}

.member-block + .member-block {
  margin-left: 42px;
}

.member-block:first-child {
  margin-left: 0;
}

.member-name,
.member-position {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 20px;
  line-height: 50px;
  color: #fff;
  position: absolute;
  bottom: 13px;
}

.member-name {
  text-transform: uppercase;
  left: 13px;
}

.member-position {
  right: 13px;
}
.member-position.with-badge {
  background-repeat: no-repeat;
  background-position: center;
  width: 68px;
  height: 68px;
  color: #000;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #000;
    z-index: 0;
  }

  .position-name {
    z-index: 1;
  }
}

@media screen and (max-width: 575px) {
  #team > .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .team-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  .member-block + .member-block {
    margin-left: 0;
    margin-top: 20px;
  }
  .member-block:first-child {
    margin-top: 0;
  }
  #team .section-subtitle {
    font-size: 19px;
  }
}
