@import '../../../constants/colors.scss';

.services-wrapper {
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 40px;
  margin: 30px auto 40px;
}

.main-services {
  width: calc(100% - 460px);
}

.services {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0px auto;
}
.services + .services {
  margin-top: 40px;
}
.service {
  font-family: 'Roboto';
  font-weight: 400;
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: 20px;
  line-height: 50px;
}
.service + .service {
  margin-top: -5px;
}
.services .service:first-child {
  margin-top: 0;
}
.service .name {
  margin-right: 15px;
  white-space: nowrap;
  /*font-weight: bold;*/
}
.service .dots {
  width: auto;
  flex: 0 1 auto;
  /*Allows too long content to be hidden.*/
  overflow: hidden;
}
.dots::before {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
}
.service .prices {
  margin-left: 15px;
  white-space: nowrap;
}

.services-subtitle {
  font-family: 'Roboto';
  font-weight: 600;
  line-height: 50px;
  font-size: 20px;
  margin-bottom: 0;
}

.services-profit-block {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background: #1e1e1e;
  position: relative;
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
  margin-left: 60px;

  .highlighted {
    color: $hightlight-color;
  }

  .services {
    margin: 0 auto;
  }

  .service {
    flex-direction: column;

    & + .service {
      margin-top: 5px;
    }
  }

  .prices,
  .price {
    margin-left: 0;
  }

  .prices {
    margin-top: -10px;
  }

  .services-subtitle {
    font-size: 30px;
    color: $hightlight-color;

    & + .services-subtitle {
      font-size: 15px;
      font-weight: 400;
      color: #fff;
      margin-top: -15px;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: solid 10px;
    border-radius: 20px;
    border-image: repeating-linear-gradient(
        -25deg,
        #fff,
        #fff 40px,
        #df5646 40px,
        #df5646 80px,
        #fff 80px,
        #fff 120px,
        #1c78a4 120px,
        #1c78a4 160px
      )
      20;
    animation: barberpole 4s linear infinite;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
  }
}

@media screen and (max-width: 992px) {
  .services-wrapper {
    flex-direction: column;
  }
  .main-services {
    width: 100%;
  }
  .services-profit-block {
    margin-left: auto;
    margin-top: 40px;
  }
}

@media screen and (max-width: 1100px) {
  .service {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .services-profit-block {
    max-width: 450px;
  }
}

@media screen and (max-width: 575px) {
  .services + .services {
    margin-top: 20px;
  }
  .services-subtitle {
    font-size: 11.22px;
    line-height: 28.05px;
  }
  .service {
    font-size: 11.22px;
    line-height: 28.05px;
  }
  .service .name {
    font-weight: bold;
    margin-right: 0;
  }
  .service .prices {
    margin-left: 0;
  }
  .service .dots:before {
    font-size: 12px;
  }

  .services-profit-block .services-subtitle {
    font-size: 18.95px;
    line-height: 31.59px;
  }

  .services-profit-block .services-subtitle + .services-subtitle {
    font-size: 11px;
    line-height: 31.59px;
  }

  .services-profit-block .service {
    font-size: 12.64px;
    line-height: 31.59px;
    text-align: left;
  }
}

@media screen and (max-width: 440px) {
  .services-wrapper {
    padding: 0 20px;
    margin-bottom: 40px;
  }
  .services-profit-block:before {
    border-width: 7px;
  }
}
