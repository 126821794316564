#reviews > .container {
  max-width: 1080px;
  padding-left: 40px;
  padding-right: 40px;
}

#reviews .reviews-wrapper {
  margin: 30px auto 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 90px;
}

#reviews .reviews {
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

#reviews .arrow {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
}

#reviews .arrow-left {
  right: 100%;
}

#reviews .arrow-right {
  transform: rotate(180deg);
  position: absolute;
  left: 100%;
}

#reviews .pagination {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

#reviews .review {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

#reviews .review img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* #reviews .review-even {
  margin-left: 40px;
} */

.dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #d9d9d9;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dot.active {
  background-color: #808080;
}

.dot + .dot {
  margin-left: 8px;
}

@media screen and (max-width: 992px) {
  #reviews .reviews {
    flex-direction: column;
    order: 1;
  }

  #reviews .review {
    max-width: 100% !important;
    margin-top: 20px;
  }

  #reviews .reviews-wrapper {
    flex-direction: column;
    margin-bottom: 80px;
  }

  #reviews .arrow {
    order: 2;
    position: absolute;
    bottom: 0;
    bottom: -50px;
    margin: 0;
  }

  #reviews .arrow-left {
    margin-right: 3px;
    right: 50%;
  }

  #reviews .arrow-right {
    margin-left: 3px;
    left: 50%;
  }

  #reviews .pagination {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  #reviews > .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
