@import './constants/colors.scss';

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #000;
}

.no-decoration {
  text-decoration: none;
  color: #fff;
}

.btn-orange {
  height: 40px;
  text-decoration: none;
  color: #fff;
  background: $button-color;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  box-shadow: none;
  border-radius: 10px;
  font-size: 15px;
  font-family: 'Inter';
  font-weight: 600;
  transition: all 0.2s ease;
  opacity: 0.9;

  &.transparent {
    background: rgba($button-color, 0.302);
    border: 1px solid $button-color;
  }

  &.square {
    width: 40px;
  }

  &.centered {
    margin: 0 auto;
  }

  &:hover {
    box-shadow: 0px 4px 10px 0px rgba($button-color, 0.502);
    opacity: 1;
  }
}

.section-title {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  text-transform: uppercase;
  text-align: center;
}

.section-subtitle {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
}

.section-title + .section-subtitle {
  margin-top: -10px;
  margin-bottom: 20px;
}

.section {
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.section > div {
  z-index: 1;
  position: relative;
}

.section:before,
.section:after {
  content: '';
  display: block;
  position: absolute;
  font-family: 'Inter';
  font-weight: 900;
  font-size: 300px;
  line-height: 50px;
  text-transform: uppercase;
  z-index: 0;
  top: 50%;
  margin-left: -100px;
}

.section:before {
  content: 'irbis';
  right: 50%;
  margin-top: -120px;
}

.section:after {
  content: 'barbershop';
  left: 50%;
  margin-top: 120px;
}

.section.gray {
  color: #000;
}

.section.black {
  color: #fff;
}

.section.gray:before,
.section.gray:after {
  color: #00000021;
}

.section.black:before,
.section.black:after {
  color: #9c9c9c21;
}

@media screen and (max-width: 320px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
