#contacts {
  margin: 0 auto 70px;
}

#contacts > .container {
  max-width: 1000px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

#contacts #map {
  max-width: 570px;
  width: 100%;
  height: 530px;
  max-height: calc(100vh - 72px);
  min-height: 100px;
  padding: 0;
  margin-left: 70px;
  border-radius: 10px;
  overflow: hidden;
}

#contacts .logo {
  width: 74px;
}

#contacts p {
  font-family: 'Roboto';
  font-size: 15px;
  line-height: 20px;
  margin: 0;
}

#contacts p.h5 {
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}

#contacts p.h5:first-of-type {
  margin-top: 40px;
}

#contacts .contacts-title {
  max-width: 275px;
  width: 100%;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  display: block;
}

#contacts .social-links {
  display: flex;
}

#contacts .social-links a {
  text-decoration: none;
}

#contacts .social-links a + a {
  margin-left: 5px;
}

@media screen and (max-width: 767px) {
  #contacts #map {
    margin-left: 50px;
    height: 350px;
  }
}

@media screen and (max-width: 575px) {
  #contacts > .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  #contacts #map {
    max-width: 130px;
    height: 130px;
    position: absolute;
    bottom: 0;
    right: 30px;
  }
  #contacts p {
    font-size: 12px;
  }

  #contacts p.h5 {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  #contacts p.h5:first-of-type {
    margin-top: 30px;
  }
}
