@import '../../constants/colors.scss';

.navbar,
.navbar .container-fluid {
  background-color: #000 !important;
}

.navbar .container-fluid {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1080px;
  margin: 0 auto;
}

.navbar-brand {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.navbar-brand img {
  width: 56px;
}

.navbar-brand span {
  color: #fff;
  font-weight: 700;
  font-size: 6px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: -5px;
}

.navbar-brand span b {
  color: $logo-text-color;
  font-size: 10px;
  text-align: center;
  margin-bottom: -2px;
}

.navbar-nav .nav-link {
  font-family: 'Inter';
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 40px;
}

.navbar .btn-orange + .btn-orange {
  margin-left: 10px;
}

.navbar .btn-orange:first-child {
  margin-left: 0px;
}

.navbar-toggler {
  padding: 10px 0;
  border: 0;
}

.navbar-collapse .order-btn {
  display: none;
  margin-top: 10px;
}
.navbar-collapse.show .order-btn,
.navbar-collapse.collapsing .order-btn {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .navbar-brand {
    margin-right: 0px;
  }

  .navbar-brand img {
    display: flex;
    height: 35px;
  }

  .navbar-nav {
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .navbar-nav .nav-link {
    padding: 0;
  }
  .navbar-nav .nav-item + .nav-item {
    margin-top: 30px;
  }
  .navbar-collapse {
    justify-content: center;
    flex-direction: column;
    display: flex;
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #000;
  }
  .navbar-collapse.collapse {
    transition: height 0.2s;
  }
  .navbar-collapse.collapsing {
    height: 0 !important;
  }
  .navbar-collapse.collapse.show {
    max-height: none;
    height: 100vh !important;
  }
}

@media screen and (max-width: 575px) {
  .navbar .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
