@import '../../../constants/colors.scss';

#portfolio {
  background: #c7c7c7;
}

#portfolio > .container {
  max-width: 1080px;
  padding-left: 40px;
  padding-right: 40px;
}

#portfolio > .section-title {
  margin-bottom: 20px;
}

.portfolio-items {
  max-width: 750px;
  margin: 0 auto;
}

.portfolio-items > div {
  padding: 5px;
}

.portfolio-items a {
  display: block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 0.75;
  padding: 7px;
}

.portfolio-items a img {
  max-width: 100% !important;
  width: 100%;
  transition: all 0.2s ease-in-out;
  margin-left: 0;
  margin-top: 0;
}

.portfolio-items a:hover {
  opacity: 1;
  padding: 0;
}

.insta-link {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  a {
    font-family: 'Roboto';
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 0 auto;
  }

  .insta-link-img {
    height: 24px;
    width: 24px;
    background-color: $button-color;
    margin-left: 5px;
  }
}

@media screen and (max-width: 575px) {
  #portfolio > .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .insta-link a {
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
  }
}
